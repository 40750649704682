
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;800&family=Roboto:wght@100;400;700;900&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  background-color: #fbfaf8;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfaf8;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

body, html {
  width: 100%;
  height: 100%;
}
